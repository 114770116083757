<template>
  <b-card
    no-body
    class="p-3"
  >
    <b-row align-h="center">
      <b-col
        sm="12"
        md="7"
      >
        <validation-observer ref="formRef">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="4">
                <b-form-group>
                  <b-form-input
                    id="tag-code"
                    size="sm"
                    v-model="form.code"
                    placeholder="BR0001"
                    :disabled="actions.isPreview"
                  />
                </b-form-group>
              </b-col>
              
              <b-col md="8">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="tag-name"
                      size="sm"
                      v-model="form.name"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="!actions.isPreview ? $t('apps.masterDataTag.placeholder.name') : null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- <b-form-group
              :label="$t('apps.masterDataTag.singular.category')"
              label-for="tag-category"
            >
              <validation-provider
                #default="{ errors }"
                name="category"
                rules="required"
              >
                <v-select
                  id="category"
                  ref="firstFocusSelectInput"
                  v-model="form.categoryId"
                  :options="LOV.category"
                  :reduce="field => field.id"
                  label="name"
                  :state="errors.length > 0 ? false:null"
                  :disabled="actions.isPreview"
                />
                <small class="text-danger">{{ errors[0]}}</small>
              </validation-provider>
            </b-form-group> -->

            <b-form-group>
              <b-form-textarea
                id="tag-description"
                v-model="form.description"
                :placeholder="!actions.isPreview ? $t('apps.masterDataTag.placeholder.description') : null"
                :disabled="actions.isPreview"
              />
            </b-form-group>

            <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
              <b-button
                class="mb-sm-1 mb-md-0"
                :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'apps-master-data-tag-list'}"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                />
                {{ $t('globalActions.backToList') }}
              </b-button>

              <b-button
                v-if="actions.isPreview"
                class=" mb-sm-1 mb-md-0"
                variant="outline-danger"
                @click="handleDelete"
              >
                <feather-icon
                  icon="Trash2Icon"
                />
                {{ $t('globalActions.delete') }}
              </b-button>

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
              </b-button>

              <b-button
                v-else
                variant="outline-primary"
                @click="changeToEdit"
              >
                <feather-icon
                  icon="EditIcon"
                />
                {{ $t('globalActions.changeToEdit') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ref, onMounted} from '@vue/composition-api'
// import i18n from '@/libs/i18n'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import useFormResource from '@/comp-functions/useFormResource'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // vSelect,
    BForm,
    BFormTextarea,
    BButton,
    ValidationProvider,
    ValidationObserver
  },
  setup () {
    const { 
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({ url: 'master/tag', localeContextPath: 'apps.masterDataTag.singular.tag', redirectPathName: 'apps-master-data-tag' })
    
    // LOV (select option)
    // const LOV = ref({
    //   category: []
    // })

    const form = ref({
      code: '',
      name: '',
      // categoryId: '',
      description: ''
    })

    // will get data to show the preview
    const getDataPreview = async () => {
      const data = await show()
      form.value.code = data[0].code
      form.value.name = data[0].name
      // form.value.categoryId = data[0].category_id
      form.value.description = data[0].description
    }

    // Get product category
    // const getCategory = async () => {
    //   LOV.value.category = await get({ url: 'value/tag-category'})
    // }

    onMounted(() => {
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
      // getCategory()
    })

    return {
      required,
      // LOV,
      form,
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    }
  },
  methods: {
    handleDelete () {
      this.destroy(this.$swal)
    },
    handleSubmit () {
      this.store({
        $swal: this.$swal,
        data: this.form
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
